import { Component, OnInit } from '@angular/core';
import { CategoryService } from './services/category.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { Category } from './interfaces/category';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {


  categories: Category[] = [];

  pages : number ;
  constructor(private categoryService: CategoryService, 
    private tokenService : TokenService, 
    private _router : Router, 
    private Auth : AuthService,
    private Notify: SnotifyService) { }

  ngOnInit() {

    this.categoryService.getCategories(1).subscribe(
      data => {
          console.log(data);
        this.categories = data['data'];
        this.pages = data['meta'].last_page;
      

      },
      error=> this.handleError(error)
    )
  } 
  getPageCategory(page){
    this.categoryService.getCategories(page+1).subscribe(
      data => {
        this.categories = data['data'];
        this.pages = data['meta'].last_page;
        console.info(this.categories);
        console.info(page);
      },
      error=> this.handleError(error)
    )
  }

  handleError(error){
  console.log(error);
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

 numbersToArray(n: number): any[] {
    return Array(n);
  }

  deleteCategory(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar esta categoría?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{

          this.categoryService.deleteCategory(_id).subscribe(
            data => {
            this.categories.splice(index,1);
              console.info(this.categories);
            },
            error => console.error(error.error)
      
          )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });



  }

}
