import { Component, OnInit } from '@angular/core';
import { Category } from './interfaces/category';
import { CategoryService } from './services/category.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm } from '@angular/forms';
import { empty } from 'rxjs';
import { INTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic/src/platform_providers';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

 category: Category = {
    _id:"",
    image: "",
    name : "",
    active : false,
    parent_id : null,
    parent_name : null,
    description: ""
  }

  categories : Category[]=[];

  selectedCategory: Category;

  nuevo: boolean = false;
  id:string;

  constructor(private categoryService : CategoryService, 
              private tokenService : TokenService, 
              private Auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService) {

              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.categoryService.getCategory(this.id).subscribe(
                      data => {
                        this.category = data['category'];
                        this.selectedCategory = this.category;
                      },
                      error => console.error(error)
                    );
                  }
                }
              )
            }

  ngOnInit() {

    this.categoryService.getCategoriesAll().subscribe(
      data => {
        this.categories = data['categories'];
      },
      error=> this.handleError(error)
    )

  }

  handleError(error){
    console.log(error);
      if(error.error[0]){
        this.Auth.changeAuthStatus(false);
        this.tokenService.remove();
        this._router.navigateByUrl('login');
      }
    }
  changeActiveStatus(){
    this.category.active = !this.category.active;
  }

  guardar(){
    if(this.id == 'add'){
      //insert
      if(this.selectedCategory !== undefined && this.selectedCategory !== null){
        this.category.parent_id = this.selectedCategory._id;
      }
      if(this.selectedCategory == null){
        this.category.parent_id = "";
      }

      this.categoryService.newCategory(this.category).subscribe(
        data=>{
          this.Notify.success("Categoría creada",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/categories']);
          },2000);

          //this._router.navigate(['/catalogs',data['catalog']._id])
        },
        error=>console.error(error)
      )
    }else{
      //update
      if(this.selectedCategory !== undefined && this.selectedCategory !== null){
        this.category.parent_id = this.selectedCategory._id;
      }
      if(this.selectedCategory == null){
        this.category.parent_id = "";
      }
      
      this.categoryService.updateCategory(this.category, this.id).subscribe(
        data=>{
         // this._router.navigate(['/catalogs',data['catalog']._id])
         this.Notify.info("Categoría editada",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/categories']);
          },2000);
        },
        error=>console.error(error)
      )
    }
  
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/category','add']);
    form.reset();

  }
}
