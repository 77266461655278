import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent2 implements OnInit {

  public name = null;
  public loggedIn:boolean;
  constructor(private Auth: AuthService,private router: Router, private Token : TokenService) { }

  ngOnInit() {
    this.Auth.authStatus.subscribe(value=>this.loggedIn = value);

    this.Auth.nameStatus.subscribe(value=>this.name = value);

    //this.name = this.getUser();
    }

    logout(event: MouseEvent){
      event.preventDefault();
      this.Token.remove();
      this.Auth.changeAuthStatus(false);//set false not login
      this.router.navigateByUrl('/login');
    }
  
  
    getUser(){
      console.log(localStorage.getItem('name'));
      return localStorage.getItem('name');
    }

}
