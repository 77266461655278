import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from "../../../../environments/environment";
import { TokenService } from '../../../services/token.service';
import { Catalog } from '../interfaces/catalog';


@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getCatalogs(page){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     console.log(page);
     return this.http.get(environment.url_api+environment.api+'/catalogs?page='+page,{ headers });
  }

  newCatalog(catalog:Catalog){
    let catalog_data = JSON.stringify(catalog);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/catalogs',catalog_data, { headers });
  }

  updateCatalog(catalog:Catalog, _id:string){
    let catalog_data = JSON.stringify(catalog);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         
    
    return this.http.put(environment.url_api+environment.api+'/catalogs/'+_id,catalog_data, { headers });
  }

  getCatalog(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/catalogs/'+_id,{ headers });
  }
  deleteCatalog(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/catalogs/'+_id,{ headers });
  }
}
