import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AttributeService } from './services/attribute.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Attribute, AttributeValue, AttributeValues } from './interfaces/attribute';

@Component({
  selector: 'app-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.css']
})
export class AttributeComponent implements OnInit {

/* 
  attribute: Attribute = {
    _id:"",
    name : "",
    categories : "",
    values :[{lang:"",value:""}]

  } */

  selected:any;
  
  attribute: Attribute = {
    _id:"",
    name : [],
    categories : "",
    values :[]
  }

  languagesAll=[
    {name: 'Español',iso: 'es',disabled:false},
    {name: 'Ingles',iso: 'en',disabled:false},
    {name: 'Francés',iso: 'fr',disabled:false},

  ];

  languagestranslated=[
   /*  {name: 'Español',iso: 'ES'}, */
    {name: 'Ingles',iso: 'en'}
  ];

  values_attr: string[] = [];


  attributes : Attribute[]=[];
  values:any;
  nuevo: boolean = false;
  id:string;

  constructor(private attributeService : AttributeService, 
              private tokenService : TokenService, 
              private Auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService) {

              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.attributeService.getAttribute(this.id).subscribe(
                      data => {
                       // console.log(data);
                        this.attribute = data['attribute'];
                        this.attribute.name = this.addLanguages(this.attribute.name);
/*                         console.log(data['attribute']) */
                        if(this.attribute.values){
                          this.attribute.values.forEach((value) => {
/*                             console.log(value)//AttributesValues */
                            value.values[0] = this.addLanguages(value.values[0])
                          })
                          this.attribute.values.push(this.generateNewAttributesValues())//add blank AttributesValues
                          }
                      },
                      error => console.error(error)
                    );
                  }else{
/*                     console.log(this.attribute); */
                    this.attribute = {    
                      _id:"",
                      name : this.addLanguages([]),
                      categories : "",
                      values :[
                        this.generateNewAttributesValues()
                      ]
                  }

                  }
                }
              )
            }

  ngOnInit() {
    this.selected = 'es';
  }

  addLanguages(items,){
  this.languagesAll.forEach((lang) => {
    let exist = false;
    let langO = {'lang':'', 'value': ''}
    items.forEach((val) => {
      if(val.lang == lang.iso){
        exist = true
      }
    })
    if(!exist){
      langO.lang = lang.iso
      items.push(langO as AttributeValue)
    }
  })
  return items
  }
  customTrackBy(index: number, obj: any): any {
    return index;
  }

  generateNewAttributesValues(){
    return {
      _id: "",
      attribute_id: this.attribute._id,
      created_at: "",
      updated_at: "",
      values: [this.addLanguages([])]
    } as AttributeValues
  }

  handleError(error){
    console.log(error);
      if(error.error[0]){
        this.Auth.changeAuthStatus(false);
        this.tokenService.remove();
        this._router.navigateByUrl('login');
      }
    }

  guardar(){// falta processar los datos para poder enviarlos 
    if(this.id == 'add'){
      //insert
      console.log(this.attribute)
      let valuesAttribute = this.attribute.values;
      this.attribute.values = []
      console.log(this.attribute)

/*       this.attributeService.newAttribute(this.attribute).subscribe(
        data=>{
          this.Notify.success("Atributo creado",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/attributes']);
          },2000);
        },
        error=>console.error(error)
      ) */
    }else{
      console.log(this.attribute)

      /*        this.attributeService.updateAttribute(this.attribute, this.id).subscribe( data=>{ // this._router.navigate(['/catalogs',data['catalog']._id])
         this.Notify.info("Atributo editado",{timeout:1000});//0 = gets default timeout setTimeout(() => { this._router.navigate(['/attributes']); },2000); }, error=>console.error(error)
      )  */
    }
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/attribute','add']);
    form.reset();
  }

/*   addNewTranslation(){
    console.log(this.selected);

    let languagerepetaed = this.languagestranslated.find(lang=>lang.iso == this.selected);
    console.log(languagerepetaed);

    if(languagerepetaed == undefined){//si ningun valor tiene una traduccion para ese idioma
      this.attribute.values.forEach(element => {
        console.log(element);

        let total = element.values.length;
        for (let index = 0; index < total; index++) {
          element.values[index].push(
            {
              lang: this.selected.toLowerCase(),
              value:''
            }
        );
        }
        
      });

      this.languagestranslated.push({name: '',iso: this.selected.toLowerCase()});

      let languagetodisable = this.languagesAll.find(lang=>lang.iso == this.selected.toLowerCase());

      if(languagetodisable != undefined){
          languagetodisable.disabled=true;
      }

      let languagetoaddName = this.languagestranslated.find(lang=>lang.iso == this.selected.toLowerCase());

      if(languagetoaddName != undefined){
          this.attribute.name.push(            
            {
            lang: this.selected.toLowerCase(),
            value:''
            }
          );
      }
    }else{
      this.attribute.values.forEach(element => {
        let languagerepetaedvalur = element.find(valor=>valor.lang == this.selected);
        console.log(languagerepetaedvalur,element);
      });
    }
    //attrvalue.push({lang: 'fr',value:'blabla'});
   // this.languagestranslated.push({name: 'Francés',iso: 'FR'});
  } */

  addNewValue(){
/*     console.log(this.attribute.values);
    console.log(this.languagestranslated);
    let valuestopush=[];
    this.languagestranslated.forEach(element => {
      valuestopush.push({lang: element.iso.toLowerCase(),value:''})
    }); 
 */
/*    this.attribute.values.push(valuestopush); */
    this.attribute.values.push(this.generateNewAttributesValues())//add blank AttributesValues
  }
  changeValue(e){
    console.log(e)
  }

}
