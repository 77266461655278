import { Component, OnInit } from '@angular/core';
import { Supplier } from './interfaces/supplier';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm } from '@angular/forms';
import { SupplierService } from './service/supplier.service';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css']
})
export class SupplierComponent implements OnInit {

  supplier:Supplier = {
    _id:"",
    name :"",
    active: false,
    image: "",
    description: "",
    phone: "",
    address: ""
  }

  nuevo: boolean = false;
  id:string;

  constructor(private supplierService : SupplierService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService) {

              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.supplierService.getSupplier(this.id).subscribe(
                      data => {
                        console.log(data);
                        this.supplier = data['supplier'];
                      },
                      error => console.error(error)
                    );
                  }
                }
              )
            }

  ngOnInit() {
  }

  guardar(){
    if(this.id == 'add'){
      //insert
      this.supplierService.newSupplier(this.supplier).subscribe(
        data=>{
          this.Notify.success("Proveedor creado",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/suppliers']);
          },2000);

          //this._router.navigate(['/catalogs',data['catalog']._id])
        },
        error=>console.error(error)
      )
    }else{
      //update
      this.supplierService.updateSupplier(this.supplier, this.id).subscribe(
        data=>{
         // this._router.navigate(['/catalogs',data['catalog']._id])
         this.Notify.info("Proveedor editado",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/suppliers']);
          },2000);
        },
        error=>console.error(error)
      )
    }
  
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/supplier','add']);
    form.reset();

  }

  changeActiveStatus(){
    this.supplier.active = !this.supplier.active;
  }

}
