import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class OrdersComponent implements OnInit {
data:any;
datapie:any;
  constructor() { 
    this.datapie = {
      labels: ['0-50€','50-200€','200-500€','500-100€','1000-1500€'],
      datasets: [
          {
              data: [300, 220, 90,60,10],
              backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "red",
                  "black"
              ],
              hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "red",
                  "black"
              ]
          }]    
      };

    this.data = {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
      datasets: [
          {
              label: 'Año 2017',
              backgroundColor: 'rgba(179,181,198,0.2)',
              borderColor: 'rgba(179,181,198,1)',
              pointBackgroundColor: 'rgba(179,181,198,1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(179,181,198,1)',
              data: [65, 59, 90, 81, 56, 55, 40,90, 81, 56, 55, 40]
          },
          {
              label: 'Año 2018',
              backgroundColor: 'rgba(255,99,132,0.2)',
              borderColor: 'rgba(255,99,132,1)',
              pointBackgroundColor: 'rgba(255,99,132,1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(255,99,132,1)',
              data: [80, 48, 40, 19, 56, 27, 28, 48, 40, 79, 96, 27]
          }
      ]
  };
  }

  ngOnInit() {
  }

}
