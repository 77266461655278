import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.css']
})
export class ResponseResetComponent implements OnInit {

  public errors = [];
  public form = {
    email:null,
    password:null,
    password_confirmation: null,
    resetToken :null
  }
  constructor(private route: ActivatedRoute, private Auth: AuthService, private router: Router, private Notify : SnotifyService) { 

   
    route.queryParams.subscribe(params=>this.form.resetToken = params['token'])

  }

  onSubmit(){
    this.Auth.changePassword(this.form).subscribe(
      data=> this.handleResponse(data),
      error=> this.handleError(error)
    )
  }

  handleResponse(data){    

    let _router = this.router;
    this.Notify.confirm('Done, Now login with the new password', {
      buttons:[
        {text : 'Ok', 
        action: toaster =>{
            _router.navigateByUrl('/login'),
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });
    //this.router.navigateByUrl('/login');
  }
  handleError(error){

    this.errors = error.error.errors;
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
/*     body.classList.remove('skin-blue');
    body.classList.remove('hold-transition');
    body.classList.remove('sidebar-mini');
    body.classList.remove('register-page');
 
    body.classList.add('hold-transition');
    body.classList.add('login-page'); */
  }

  ngOnDestroy(): void {
   const body = document.getElementsByTagName('body')[0];
/*    body.classList.remove('hold-transition');
   body.classList.remove('login-page');
   body.classList.add('skin-blue');
    body.classList.add('hold-transition');
    body.classList.add('sidebar-mini'); */
  }

}
