import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AttributeService } from './../services/attribute.service';
import { TokenService } from './../../../services/token.service';
import { AuthService } from './../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Attribute, AttributeValue } from './../interfaces/attribute';

@Component({
  selector: 'app-attributevalues',
  templateUrl: './attribute_values.component.html'
})
export class AttributeValuesComponent implements OnInit {
  @Input() languages; 
  @Input() selected: string; 
  @Output() changeValue = new EventEmitter();
  @Input() value: AttributeValue[];
  languagesAll=[
  ];

  languagestranslated=[
  ];

  values_attr: string[] = [];


  attributes : Attribute[]=[];
  nuevo: boolean = false;
  id:string;

  constructor(private tokenService : TokenService, 
              private Auth: AuthService, 
              private _router : Router,
              private Notify: SnotifyService) {
            }

  ngOnInit() {
    if(this.languages){
      this.languagesAll = this.languages;
    }
/*     this.selected = 'es'; */
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  handleError(error){
    console.log(error);
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  onChange(){
    this.changeValue.emit(this.value)
  }
}
