import { Component, OnInit } from '@angular/core';
import { Attribute } from './interfaces/attribute';
import { AttributeService } from './services/attribute.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.css']
})
export class AttributesComponent implements OnInit {


  attributes: Attribute[] = [];

  pages : number ;
  constructor(private attributeService: AttributeService, 
              private tokenService : TokenService, 
              private _router : Router, 
              private Auth : AuthService,
              private Notify: SnotifyService) { }

  ngOnInit() {

    this.attributeService.getAttributes(1).subscribe(
      data => {
        
        this.attributes = data['attributes'].data;
        this.pages = data['attributes'].last_page;
        console.log(data);

      },
      error=> this.handleError(error)
    )
  } 
  getPageAttribute(page){
    this.attributeService.getAttributes(page+1).subscribe(
      data => {
        this.attributes = data['attributes'].data;
        this.pages = data['attributes'].last_page;
        console.info(this.attributes);
        console.info(page);
      },
      error=> this.handleError(error)
    )
  }

  handleError(error){
  console.log(error);
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

 numbersToArray(n: number): any[] {
    return Array(n);
  }

  deleteAttribute(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar este atributo?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{

          this.attributeService.deleteAttribute(_id).subscribe(
            data => {
            this.attributes.splice(index,1);
              console.info(this.attributes);
            },
            error => console.error(error.error)
      
          )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });
  }

}
