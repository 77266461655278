import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public form = {
    email:null,
    password:null
  }
  public errors = null;

  //constructor(private http: HttpClient) { }
  constructor(private Auth: AuthService,
              private Token:TokenService,
              private router: Router
            ) { }

  onSubmit(){
    /* return this.http.post(environment.url_api+environment.api+'/login',this.form).subscribe(
      data => console.log(data),
      error => this.handleError(error)
    ); */

    this.Auth.login(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }


  handleError(error){
    this.errors = error.error.error;
  }
  handleResponse(data){
    //console.log(data);
    this.Token.handle(data);
    this.Auth.changeAuthStatus(true);
    this.Auth.changeNameStatus(data.user);
    this.router.navigateByUrl('/dashboard');
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
/*     body.classList.remove('skin-blue');
    body.classList.remove('hold-transition');
    body.classList.remove('sidebar-mini');
    body.classList.remove('register-page');
 
    body.classList.add('hold-transition');
    body.classList.add('login-page'); */
  }

  ngOnDestroy(): void {
   const body = document.getElementsByTagName('body')[0];
/*    body.classList.remove('hold-transition');
   body.classList.remove('login-page');
   body.classList.add('skin-blue');
    body.classList.add('hold-transition');
    body.classList.add('sidebar-mini'); */
  }

}
