import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { TokenService } from './token.service';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private Token: TokenService) { }

  private loggedIn = new BehaviorSubject<boolean>(this.Token.loggedIn());
  private token = null;
  authStatus = this.loggedIn.asObservable();

  changeAuthStatus(value:boolean){
    this.loggedIn.next(value);//emitir un nuevo valor en el flujo de datos que maneja el Observable
  }


  
  private name = new BehaviorSubject<String>(this.Token.getName());
 
  nameStatus = this.name.asObservable();

  changeNameStatus(value:String){
    this.name.next(value);//emitir un nuevo valor en el flujo de datos que maneja el Observable
  }


  signup(data){
    return this.http.post(environment.url_api+environment.api+'/signup',data)
  }
  login(data){
    return this.http.post(environment.url_api+environment.api+'/login',data)
  }

  sendPasswordResetLink(data){
    return this.http.post(environment.url_api+environment.api+'/sendPasswordResetLink',data)
  }

  changePassword(data){
    return this.http.post(environment.url_api+environment.api+'/resetPassword',data)
  }

 getProfile(data){
   this.token = this.Token.get();

   let headers = new HttpHeaders()
                    .set('Content-Type', 'application/json')             
                    .set('authorization', 'Bearer ' + this.token);
                    
    return this.http.post(environment.url_api+environment.api+'/me',data,{ headers: headers })
 }

}
