import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core'

import { AppComponent } from './app.component';
//import { NavbarComponent } from './components/navbar/navbar.component';
import { SignupComponent } from './components/signup/signup.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LoginComponent } from './components/login/login.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { AfterLoginService } from './services/after-login.service';
import { BeforeLoginService } from './services/before-login.service';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ProfileService } from './services/profile.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent2 } from './components/shared/navbar/navbar.component';
import { CatalogsComponent } from './components/catalogs/catalogs.component';
import { Catalog } from './components/catalogs/interfaces/catalog';
import { CatalogService } from './components/catalogs/services/catalog.service';
import { CatalogComponent } from './components/catalogs/catalog.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductComponent } from './components/products/product.component';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { EditorModule } from "primeng/editor";
import {SidebarModule} from 'primeng/sidebar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import {OverlayPanelModule} from 'primeng/overlaypanel';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {KeyFilterModule} from 'primeng/keyfilter';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryComponent } from './components/categories/category.component';
import { AttributesComponent } from './components/attributes/attributes.component';
import { AttributeComponent } from './components/attributes/attribute.component';
import { CategoryService } from './components/categories/services/category.service';
import {DropdownModule} from 'primeng/dropdown';
import { ImportsComponent } from './components/imports/imports.component';
import { ExportsComponent } from './components/exports/exports.component';

import {MultiSelectModule} from 'primeng/multiselect';
import {ChipsModule} from 'primeng/chips';
import {ChartModule} from 'primeng/chart';
import {GrowlModule} from 'primeng/growl';
import {DialogModule} from 'primeng/dialog';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { SupplierComponent } from './components/suppliers/supplier.component';
import { Slim } from './slim/slim.angular2';
import { OrdersComponent } from './components/orders/orders.component';
import { StocksComponent } from './components/stocks/stocks.component';
import { PricesComponent } from './components/prices/prices.component';

import { BrandComponent } from './components/brands/brand.component';
import { StockComponent } from './components/stocks/stock.component';
import { StockService } from './components/stocks/services/stock.service';
import { BrandService } from './components/brands/services/brand.service';
import { ProductResolver } from './components/products/resolver/product-resolver.service';
import { BrandsResolver } from './components/products/resolver/brands-resolver.service';
import { AttributeValuesComponent } from './components/attributes/attribute_values/attribute_values.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent2,
    SignupComponent,
    ProfileComponent,
    LoginComponent,
    RequestResetComponent,
    ResponseResetComponent,
    DashboardComponent,
    CatalogsComponent,
    CatalogComponent,
    ProductsComponent,
    ProductComponent,
    CategoriesComponent,
    CategoryComponent,
    AttributesComponent,
    AttributeComponent,
    ImportsComponent,
    ExportsComponent,
    SuppliersComponent,
    SupplierComponent,
    Slim,
    OrdersComponent,
    StocksComponent,
    PricesComponent,
    StockComponent,
    BrandComponent,
    AttributeValuesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SnotifyModule,
    FileUploadModule,
    EditorModule,
    SidebarModule,
    BrowserAnimationsModule,
    //OverlayPanelModule,
    CalendarModule,
    InputSwitchModule,
    KeyFilterModule,
    DropdownModule,
    MultiSelectModule,
    ChipsModule,
    ChartModule,
    GrowlModule,
    DialogModule,
    ReactiveFormsModule
  
  ],
  providers: [AuthService, TokenService, AfterLoginService, BeforeLoginService,
              { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
              SnotifyService,
              ProfileService,
              CatalogService,
              CategoryService,
              StockService,
              BrandService,
              ProductResolver,
              BrandsResolver
            ],
  bootstrap: [AppComponent]
})
export class AppModule { }
