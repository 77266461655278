import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { ImportService } from './services/import.service';

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.css']
})
export class ImportsComponent implements OnInit {

  images:any;
  form: FormGroup;
  prestaform: FormGroup = new FormGroup({
                 
  });

  constructor(private importService : ImportService, 
              private Notify: SnotifyService,private fb: FormBuilder) {

           
                this.form = this.fb.group({
                  name: ['', Validators.required],
                  csv: null
                });

            }

  ngOnInit() {
  }
  getFiles(event) {
    console.log(event.target.files);
    this.images = event.target.files;
}

onFileChange(event) {
  let reader = new FileReader();
  if(event.target.files && event.target.files.length > 0) {
    let file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.form.get('csv').setValue({
        filename: file.name,
        filetype: file.type,
        value: reader.result.split(',')[1]
      })
    };
  }
}

  guardar(){

    const formModel = this.form.value;
    console.log(formModel);
  


     this.importService.postCsvProducts(formModel).subscribe(
      data=>{
        console.log(data);
        this.Notify.success("Csv enviado",{timeout:1000});//0 = gets default timeout
        setTimeout(() => {
          //this._router.navigate(['/catalogs']);
        },2000);

        //this._router.navigate(['/catalogs',data['catalog']._id])
      },
      error=>console.error(error)
    ) 

  }

}
