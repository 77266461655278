import { Component, OnInit, Input } from '@angular/core';
import { StockService } from './services/stock.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Stock } from './interfaces/stocks';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html'
})
export class StockComponent implements OnInit {

  stock:Stock = {
    _id: "",
    product_id:"",
    combination_id: "",
    quantity: 0,
    user: ""
  }

  @Input() htmlentity: boolean;
  nuevo: boolean = false;
  id:string;

  constructor(private stockService : StockService, 
              private tokenServide : TokenService, 
              private auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService) {

              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.stockService.getStock(this.id).subscribe(
                      data => {
                        console.log(data);
                        this.stock = data['stock'];
                      },
                      error => console.error(error)
                    );
                  }
                }
              )
              if(!this.htmlentity){
                console.log('jalo')
              }else{
                console.log('jalo1')
              }
            }

  ngOnInit() {
  }

  guardar(){
    if(this.id == 'add'){
      //insert
      console.log(this.stock)
      this.stockService.newStock(this.stock).subscribe(
        data=>{
          this.Notify.success("Stock creado",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/stocks']);
          },2000);

          //this._router.navigate(['/catalogs',data['catalog']._id])
        },
        error=>console.error(error)
      )
    }else{
      console.error('update');
      //update
      this.stockService.updateStock(this.stock, this.id).subscribe(
        data=>{
         // this._router.navigate(['/catalogs',data['catalog']._id])
         this.Notify.info("Stock editado",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/stocks']);
          },2000);
        },
        error=>console.error(error)
      )
    }
  
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/stock','add']);
    form.reset();
  }

}