import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from "../../../../environments/environment";
import { TokenService } from '../../../services/token.service';
import { NgForm } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  postCsvProducts(images){ 
    //let form_data = JSON.stringify(form);


    let headers = new HttpHeaders()
                      //.set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/csv/products', images, { headers });
  }

}