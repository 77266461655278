import { Injectable } from '@angular/core';
import { TokenService } from '../../../services/token.service';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Product } from '../interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getProducts(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/products',{ headers });
  }

  newProduct(product: Product){
    let product_data = JSON.stringify(product);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/products',product_data, { headers });
  }

  updateProduct(product: Product, _id:string){
    let product_data = JSON.stringify(product);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    
    return this.http.put(environment.url_api+environment.api+'/products/'+_id,product_data, { headers });
  }

  getProduct(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/products/'+_id,{ headers });

  }
  deleteProduct(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/products/'+_id,{ headers });
  }
}
