import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from '../../../../environments/environment';
import { Brand } from '../interfaces/brand';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getBrands(page){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         

     return this.http.get(environment.url_api+environment.api+'/brands?page='+page,{ headers });
  }

  getBrandsAll(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         

     return this.http.get(environment.url_api+environment.api+'/brands_all',{ headers });
  }

  newBrand(brand:Brand){
    let brand_data = JSON.stringify(brand);
    console.log(brand_data);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/brands',brand_data, { headers });
  }

  updateBrand(brand:Brand, _id:string){
    let brand_data = JSON.stringify(brand);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    
    return this.http.put(environment.url_api+environment.api+'/brands/'+_id,brand_data, { headers });
  }

  getBrand(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/brands/'+_id,{ headers });

  }
  deleteBrand(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/brands/'+_id,{ headers });
  }

}
