import { Component, OnInit } from '@angular/core';
import { Product } from './interfaces/product';
import { ProductsService } from './services/products.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Message } from 'primeng/components/common/message';
import { environment } from '../../../environments/environment';
import { Category } from '../categories/interfaces/category';
import { CategoryService } from '../categories/services/category.service';
import { BrandService } from '../brands/services/brand.service';
import { Brand } from '../brands/interfaces/brand';




@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {


  environment = environment;
  visibleSidebar5 = true;

  product:Product = {
    _id:"",
    name : "",
    description_short :"",
    description_long :"",
    image_cover : "",
    images : {},
    active : false,
    sku : "",
    sku_supplier : "",
    ean13 : "",
    category_default :"",
    categories : "",
    position : "",
    cost_price : null,
    price : null,
    tax : null,
    activation_date : "",
    order_without_stock : true,
    brand : ""
  }
  checked: boolean = true;
  nuevo: boolean = false;
  id:string;

  msgs: Message[];
    
  uploadedFiles: any[] = [];
  uploadedFiles2: any[] = [];

  categories : Category[]=[];
  selectedCategory: Category;
  category: Category = {
    _id:"",
    image: "",
    name : "",
    active : false,
    parent_id : null,
    parent_name : null,
    description: ""
  }

  selectedCategories : Category[]=[];

  brands: Brand[]=[];
  selectedBrand: Brand;
  brand: Brand = {
    _id: "",
    name: "",
    active : true,
    user: "",
    image: ""
  }
    displayNewBrand = false;

  /**
   * Slim utils
   */
  

   actionType: String = "Create";
  imagesForm: FormGroup;
  credentials:any;
  slimOptions = {
    ratio: '1:1',
    download: true,
    filterSharpen: '10',
    willRemove: this.imageWillBeRemoved.bind(this),
    didConfirm: this.imageDidBeConfirmed.bind(this),
    instantEdit: true,
    label: '<i class="fa fa-picture-o" aria-hidden="true"></i>',
    buttonConfirmLabel: 'Confirmar',
    buttonConfirmTitle: 'Confirmar',
    buttonCancelLabel: 'Cancelar',
    buttonCancelTitle: 'Cancelar',
    buttonEditTitle: 'Editar',
    buttonRemoveTitle: 'Eliminar',
    buttonDownloadTitle: 'Descargar',
    buttonRotateTitle: 'Rotar',
    buttonUploadTitle: 'Subir',
    statusImageTooSmall:'Imagen demasiado pequeña por favor inténtelo con otra'
  };
  optionsSlim:Object[] = [];//contiene los slimpOptions con la imagen por defecto 
  imagesRep = ['image0','image1','image2','image3'];
  imagesBase64:string[]= []; 

  /**
   * end Slim utils
   */
  

  /**
   * Form Validation
   */
  productForm: FormGroup;

  
  constructor(private productService : ProductsService,
    private tokenService : TokenService, 
    private auth: AuthService, 
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
    private categoryService : CategoryService,
    private brandService : BrandService,
    private fb: FormBuilder ) {



    this.activatedRoute.params.subscribe(
      parametros =>{
        this.id=parametros['id'];
      });

    this.activatedRoute.data.subscribe(
      (response) => {
        if(response.data && response.data.product){
          this.product = response.data.product
          this.categoryService.getCategory(this.product.category_default).subscribe(
            datacategory => {
              this.category = datacategory['category'];
              this.selectedCategory = this.category;
            },
            error => console.error(error)
          );
          if(this.product.brand){
            this.brandService.getBrand(this.product.brand).subscribe(
              databrand => {
                this.brand = databrand['brand'];
                this.selectedBrand = this.brand;
              },
              error => console.error(error)
            );
          }
        }
        if(response.data_brands && response.data_brands.brands){
          this.brands = response.data_brands.brands
        }
      },
      error => this.handleError(error)
    )

    this.productForm = this.fb.group({
      'name': new FormControl('', Validators.compose([Validators.required])),
      'description_short': new FormControl(''),
      'description_long': new FormControl(''),
      'sku': new FormControl('', Validators.compose([Validators.required])),
      'price': new FormControl('', Validators.compose([Validators.required, Validators.min(0.01)])),
      'categoryDefault': new FormControl(''),
      'categoriesAsociadas': new FormControl(''),
      'position': new FormControl(''),
      'sku_supplier': new FormControl(''),
      'ean13': new FormControl(''),
      'cost_price': new FormControl(''),
      'tax': new FormControl('', Validators.compose([Validators.required])),
      'activation_date': new FormControl(''),
      'brandDefault': new FormControl(''),
    })
  }
   
  handleError(error){
  
      if(error.error[0]){
        this.auth.changeAuthStatus(false);
        this.tokenService.remove();
        this._router.navigateByUrl('login');
      }
    }

 changeActiveStatus(){
  this.product.active = !this.product.active;
}
changeOrderStockStatus(){
  this.product.order_without_stock = !this.product.order_without_stock;
}

myCloseEvent(){
  this._router.navigate(['/products']);
}
  
ngOnInit() {
  this.generateOptions();
  this.categoryService.getCategoriesAll().subscribe(
    data => {
      this.categories = data['categories'];
    },
    error=> this.handleError(error)
  )
}

guardar(form: NgForm){
  console.log(form);
/*   console.log(this.imagesBase64); */
  let sendDatos = {};
  this.imagesRep.forEach((name, index) => {
    sendDatos[name] = this.imagesBase64[index] || "" ;
  })
/*   console.log(sendDatos);//objecto clave valor  */
  this.product.images = sendDatos;
  if(this.selectedBrand){
    this.product.brand = this.selectedBrand._id
  }else{
    this.product.brand = "" 
  }
  if(this.id == 'add'){
    //insert
        if(this.selectedCategory !== undefined && this.selectedCategory !== null){
          this.product.category_default = this.selectedCategory._id;
        }
        if(this.selectedCategory == null){
          this.product.category_default = "";
        }
        
    this.productService.newProduct(this.product).subscribe(
    data=>{
      this.Notify.success("Producto creado",{timeout:1000});//0 = gets default timeout
      setTimeout(() => {
        this._router.navigate(['/products']);
      },2000);
    },
    error=>console.error(error)
    )
  }else{

    //update
    if(this.selectedCategory !== undefined && this.selectedCategory !== null){
      this.product.category_default = this.selectedCategory._id;
    }
    if(this.selectedCategory == null){
      this.product.category_default = "";
    }
    this.productService.updateProduct(this.product, this.id).subscribe(
      data=>{
      this.Notify.info("Producto editado",{timeout:1000});//0 = gets default timeout
      setTimeout(() => {
        this._router.navigate(['/products']);
      },2000);
      },
      error=>console.error(error)
    )
  } 
}

agregarNuevo(form : NgForm){
  this._router.navigate(['/product','add']);
  form.reset();
}


/**
 * slim functions
 */

  changeImage(e:any, i:any){//save image in base64
    e.preventDefault()
    let files = e.target.files || e.dataTransfer.files;
    let file = files[0];
    if(file){
      let reader = new FileReader;
      console.log(file)
      reader.onloadend = ()=>{
        if(reader.result.match('data:image')){
          this.saveBase64(reader.result, i)//save base64
        }
      }
      reader.readAsDataURL(file);
    }
  }

  imageWillBeRemoved(data, that, remove) {
    if (window.confirm("Are you sure?")) {
      remove();
      let key = this.imagesRep.indexOf(that._input.name)//clave de la image
      this.imagesBase64[key] = undefined;
    }
  }

  imageDidBeConfirmed(data, that) {
    let key = this.imagesRep.indexOf(that._input.name)
    this.imagesBase64[key] = that.dataBase64.output.image
  }

  limitMinImages(){
    return (this.imagesBase64.filter(Boolean).length >= 1)
  }

  saveBase64(base64, i){
      this.imagesBase64[i] = base64
  } 

  generateOptions(){
    this.imagesRep.forEach((name,i)=> {
      this.optionsSlim[name] = this.getImageDefault(name,i)
    })
  } 

  getImageDefault(name,i){
    if(this.product.images[name]){
      this.saveBase64(this.product.images[name], i)
      if(i == 0){
        return { ...this.slimOptions, 'initialImage': this.product.images[name], 'label': "Esta imagen será la portada de tu producto"+this.slimOptions.label};
      }
      return { ...this.slimOptions, 'initialImage': this.product.images[name] };
    }
    if(i == 0){
      return { ...this.slimOptions, 'initialImage': this.product.images[name], 'label': "Esta imagen será la portada de tu producto"+this.slimOptions.label};
    }
    return this.slimOptions
  }

  newBrand(id){
    this.displayNewBrand = false;
    if(id.brand){
      let brand:Brand = id.brand
      this.brands = [brand, ...this.brands]//no se puede usar push ya que no lo detecta como un cambio
      this.selectedBrand = id.brand
    }
  }
  
  validateFirstImage(){
    if(this.imagesBase64 && this.imagesBase64[0]){
      return true;
    }
    return false;
  }

  validForm(){
    return this.productForm.valid && this.validateFirstImage()
  }
}

