import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StockService } from './services/stock.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Stock } from './interfaces/stocks';
import { SnotifyService } from 'ng-snotify';
import { Token } from '@angular/compiler';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  encapsulation: ViewEncapsulation.Emulated
})
export class StocksComponent implements OnInit {

  stocks: Stock[] = [];
  pages:number;

  constructor(private stockService: StockService, private tokenService: TokenService, private _router : Router, private Auth : AuthService,
  private Notify: SnotifyService) { }

  ngOnInit() {
    this.stockService.getStocks(1).subscribe(
      data => {
        
        this.stocks = data['stocks'].data;
        this.pages = data['stocks'].last_page;
        console.log(data);

      },
      error=> this.handleError(error)
    )
  }

  getPageStock(page){
    this.stockService.getStocks(page+1).subscribe(
      data => {
        this.stocks = data['stocks'].data;
        this.pages = data['stocks'].last_page;
        console.info(this.stocks);
        console.info(page);
      },
      error=> this.handleError(error)
    )
  }

  handleError(error){
  console.log(error);
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  numbersToArray(n: number): any[] {
    return Array(n);
  }

  deleteStock(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar este stock ?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{

          this.stockService.deleteStock(_id).subscribe(
            data => {
            this.stocks.splice(index,1);
              console.info(this.stocks);
            },
            error => console.error(error.error)
      
          )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });
  }
}