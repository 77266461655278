import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';

@Injectable()
export class AfterLoginService implements CanActivate{
public tokenValid = false;
public logged ;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    //throw new Error("Method not implemented.");

  /*   this.Token.checkTokenValid().subscribe(
      data => this.valid(true),
      error => this.valid(error.error[0])
    ); */

 // this.logged = this.Token.loggedIn() 

   /*  if( this.logged && !this.tokenValid){
      this.Auth.changeAuthStatus(true);
      this.router.navigateByUrl('/profile');
    } */
   // return this.logged  && this.tokenValid;


   /**
    * Checkear si el token ha expirado o no y si esta el token y viene de integrafy
    */
   if(!this.Token.loggedIn()){
    this.router.navigateByUrl('/login');
   }
 return  this.Token.loggedIn();
  }

  valid(param){
    if(param !== true){
      this.tokenValid = false;
      this.Token.remove();

    }else{
      this.tokenValid = true;
    }
    
  }
  constructor(private Token: TokenService, private Auth : AuthService,private router: Router) { }

}
