import { Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BrandService } from '../../brands/services/brand.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BrandsResolver implements Resolve<Object> {
    constructor(
        private brandService: BrandService,
        private router: Router,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return  this.brandService.getBrands(1);
    }
}