import { Component, OnInit } from '@angular/core';
import { Product } from './interfaces/product';
import { ProductsService } from './services/products.service';
import { TokenService } from '../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import {LightboxModule} from 'primeng/lightbox';
import { CategoryService } from '../categories/services/category.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  products: Product[] = [];
  constructor(private productService: ProductsService, 
    private tokenService : TokenService, 
    private _router : Router, 
    private Auth : AuthService,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
    private categoryService : CategoryService,
  ) {

 

   }

  ngOnInit() {

    this.productService.getProducts().subscribe(
      data => {
        console.log(data);
        this.products = data['products'];
      },
      error=> this.handleError(error)
    )
  } 

  handleError(error){
    console.log(error);
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  deleteProduct(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar este producto?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{
            //this._router.navigateByUrl('/login'),
            this.productService.deleteProduct(_id).subscribe(
              data => {
              this.products.splice(index,1);
              },
              error => console.error(error.error)
            )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });
  }

  duplicateProduct(product : Product){

    console.log(product);
  }

  convertImage(image_cover){

    let images = [];
    images.push({source: image_cover, thumbnail: image_cover, title:'Sopranos 1'});
    return images;
    
  }

}
