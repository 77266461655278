import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { MethodSend } from './interfaces/methodSend';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profile = {
    fullname: "",
    image: "",
    accountBank: "",
    emailNotifications: 1,
    newsletter: 1,
    methodSend: "",
    address: ""
  }

  methodssend: MethodSend[]= [];
  selectedMethodSend: MethodSend;
  methodsend: MethodSend = {
    _id:"",
    name : "",
  }

  slimOptions = {
    ratio: '1:1',
    download: true,
    filterSharpen: '10',
    willRemove: this.imageWillBeRemoved.bind(this),
    didConfirm: this.imageDidBeConfirmed.bind(this),
    instantEdit: true,
    label: '<i class="fa fa-picture-o" aria-hidden="true"></i>',
    buttonConfirmLabel: 'Confirmar',
    buttonConfirmTitle: 'Confirmar',
    buttonCancelLabel: 'Cancelar',
    buttonCancelTitle: 'Cancelar',
    buttonEditTitle: 'Editar',
    buttonRemoveTitle: 'Eliminar',
    buttonDownloadTitle: 'Descargar',
    buttonRotateTitle: 'Rotar',
    buttonUploadTitle: 'Subir',
    statusImageTooSmall:'Imagen demasiado pequeña por favor inténtelo con otra'
  };
  
  constructor(private Auth : AuthService, private tokenService: TokenService, private _router : Router) { 
    this.methodssend = [{_id: "1", name:"CORREOS"}, {_id: "2",name:"MRW"}]
  }

  ngOnInit() {
  }

  getProfile(){
    this.Auth.getProfile('test').subscribe(
      data => console.log(data),
      error => this.handleError(error)
    );
  }

  handleError(error){
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  guardar(form: NgForm){
  if(this.selectedMethodSend){
    this.profile.methodSend = this.selectedMethodSend._id
  }else{
    this.profile.methodSend = "" 
  }
    console.log(this.profile)
  }
  
  //process events images

  changeImage(e:any, i:any){//save image in base64
    e.preventDefault()
    let files = e.target.files || e.dataTransfer.files;
    let file = files[0];
    if(file){
      let reader = new FileReader;
      console.log(file)
      reader.onloadend = ()=>{
        if(reader.result.match('data:image')){
          this.profile.image = reader.result//save base64
        }
      }
      reader.readAsDataURL(file);
    }
  }
  imageWillBeRemoved(data, that, remove) {
    if (window.confirm("Are you sure?")) {
      remove();
      this.profile.image = "";
    }
  }
  imageDidBeConfirmed(data, that) {
    this.profile.image = that.dataBase64.output.image
  }
}
