import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class TokenService {

  private iss = 
    [
     environment.url_api+environment.api+'/login',
     environment.url_api+environment.api+'/signup'
    ]
  
  constructor(private http: HttpClient) { }

  handle(token){
    this.set(token);
  }

  set(token){
    localStorage.setItem('token',token.access_token);
    localStorage.setItem('name',token.user);
   // console.log(token.user);
  }

  getName(){
    return localStorage.getItem('name');
  }
  get(){
    return localStorage.getItem('token');
  }
  remove(){
    localStorage.removeItem('token');
    localStorage.removeItem('name');
  }

  isValid(){
    const token = this.get();

    if(this.get()){
      const payload = this.payload(token);
      //console.log(payload);
      if(payload){
        
        return this.iss.indexOf(payload.iss) < 0 ? false : true;
      }
    }
    return false;
  }

  payload(token){
 
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload){
    return JSON.parse(atob(payload));
  }

  loggedIn(){
    return this.isValid();
  }

  checkTokenValid(){

    const tokenToCheck = this.get();

    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')             
                     .set('authorization', 'Bearer ' + tokenToCheck);
                     
     return this.http.post(environment.url_api+environment.api+'/checkToken',null,{ headers: headers })

  }


}
