import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { SnotifyService, ToastDefaults} from 'ng-snotify';

@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.css']
})
export class RequestResetComponent implements OnInit {

  public form ={
    email: null
  }
  constructor(private Auth: AuthService, private snotifyService : SnotifyService, private Notify: SnotifyService) { }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
/*     body.classList.remove('skin-blue');
    body.classList.remove('hold-transition');
    body.classList.remove('sidebar-mini');
    body.classList.remove('register-page');
 
    body.classList.add('hold-transition');
    body.classList.add('login-page'); */
  }

  ngOnDestroy(): void {
   const body = document.getElementsByTagName('body')[0];
/*    body.classList.remove('hold-transition');
   body.classList.remove('login-page');
   body.classList.add('skin-blue');
    body.classList.add('hold-transition');
    body.classList.add('sidebar-mini'); */
  }

  onSubmit(){
this.Notify.info('Wait...', {timeout:5000});

    this.Auth.sendPasswordResetLink(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.snotifyService.error(error.error.error)
      
    );
  }

  handleResponse(res){
    this.Notify.success(res.data,{timeout:0});//0 = gets default timeout
    this.form.email = null;
  }
}
