import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from "../../../../environments/environment";
import { TokenService } from '../../../services/token.service';
import { Supplier } from '../interfaces/supplier';


@Injectable({
  providedIn: 'root'
})
export class SupplierService {


  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getSuppliers(page){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     console.log(page);
     return this.http.get(environment.url_api+environment.api+'/suppliers?page='+page,{ headers });
  }

  newSupplier(supplier:Supplier){
    let supplier_data = JSON.stringify(supplier);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/suppliers',supplier_data, { headers });
  }

  updateSupplier(supplier:Supplier, _id:string){
    let supplier_data = JSON.stringify(supplier);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    
    return this.http.put(environment.url_api+environment.api+'/suppliers/'+_id,supplier_data, { headers });
  }

  getSupplier(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/suppliers/'+_id,{ headers });

  }
  deleteSupplier(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/suppliers/'+_id,{ headers });
  }
}
