import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.css']
})
export class ExportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  exportProducts(){
    let products = {
      "name" : "Test product",
      "description_short" : "<p>ffwefwefew</p>",
      "description_long" : "<p>fewfwefwe</p>",
      "image_cover" : null,
      "images" : {
          "image0" : "",
          "image1" : null,
          "image2" : null,
          "image3" : null
      },
      "active" : true,
      "sku" : "airmax_12",
      "ean13" : null,
      "category_default" : "5b2ba2e50cd4dc2eac003ac4",
      "categories" : null,
      "position" : null,
      "cost_price" : null,
      "price" : "59",
      "tax" : "21",
      "activation_date" : null,
      "order_without_stock" : true,
      "user" : "5b1519130cd4dc3644007fe4"
  };

    var data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(products));
            var downloader = document.createElement('a');

            downloader.setAttribute('href', data);
            downloader.setAttribute('download', 'products.json');
            downloader.click();
  }



  exportCategories(){}
  exportStocks(){}
  exportAttributes(){}


}
