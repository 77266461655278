import { Component, OnInit } from '@angular/core';
import { SupplierService } from './service/supplier.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { Supplier } from './interfaces/supplier';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {


  suppliers: Supplier[] = [];

  pages : number ;
  constructor(private supplierService: SupplierService, 
    private tokenService : TokenService, 
    private _router : Router, 
    private Auth : AuthService,
    private Notify: SnotifyService) { }

  ngOnInit() {

    this.supplierService.getSuppliers(1).subscribe(
      data => {
          console.log(data);
        this.suppliers = data['suppliers'].data;
        this.pages = data['suppliers'].last_page;

      

      },
      error=> this.handleError(error)
    )
  } 
  getPageSupplier(page){
    this.supplierService.getSuppliers(page+1).subscribe(
      data => {
        this.suppliers = data['data'];
        this.pages = data['meta'].last_page;
        console.info(this.suppliers);
        console.info(page);
      },
      error=> this.handleError(error)
    )
  }

  handleError(error){
  console.log(error);
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

 numbersToArray(n: number): any[] {
    return Array(n);
  }

  deleteSupplier(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar este proveedor?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{

          this.supplierService.deleteSupplier(_id).subscribe(
            data => {
            this.suppliers.splice(index,1);
              console.info(this.suppliers);
            },
            error => console.error(error.error)
      
          )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });



  }

}
