import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Message } from 'primeng/components/common/message';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  public name = null;
  data: any;

  data2: any;
    
  msgs: Message[];
  constructor() {
      this.data = {
          labels: ['0-50€','50-200€','200-1500€'],
          datasets: [
              {
                  data: [300, 50, 100],
                  backgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#FFCE56"
                  ],
                  hoverBackgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#FFCE56"
                  ]
              }]    
          };

          this.data2 = {
            labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Juloi'],
            datasets: [
                {
                    label: '2017',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: '#4bc0c0'
                },
                {
                    label: '2018',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: '#565656'
                }
            ]
        }

  }







  selectData(event) {
      this.msgs = [];
      this.msgs.push({severity: 'info', summary: 'Value Selected', 'detail': this.data2.datasets[event.element._datasetIndex].data[event.element._index]});
  }




  ngOnInit() {

    this.name = this.getUser;
  }

  getUser(){
    return localStorage.getItem('name');
  }
  
  

}
